import React, { useMemo, useContext, useState } from "react";

import { Formik, Form } from "formik";
import FormTextInput from "../../components/form-text-input/form-text-input";
import { Dialog } from 'primereact/dialog';
import { useMutation } from '../../hooks';

import { css } from "./css";
import ModalButtonSet from '../modal-set-button/modal-set-button';
import FormSelect from '../../components/form-select/form-select-simplified';
import { appColors } from '../../emotion/appColors';

import { useQuery } from '../../hooks';

import Client, { ENDPOINT } from '../../client';
import FormCheckBox from '../form-check-box/form-check-box';
import * as Yup from 'yup';
import { debounce } from 'lodash';
import AuthContext from '../../context/auth-context';
import { flexbox, fontbox, fullWidth } from "../../emotion/utils";
import { appFonts } from "../../emotion/appFonts";

export default function EditUserModal({
  setShowModal = () => {},
  open,
  onSuccess = () => {},
  user
}) {
  const handleCancel = () => {
    setShowModal(false)
  }

  const { runProtected, userData } = useContext(AuthContext);
  const [merging, setMerging] = useState(null)
  const [taken, setTaken] = useState(false)

  const duplicateTest = (field) => debounce(async (value) => {
    try {
      const res = await runProtected(Client.get, `/users?${field}=${value}`);
      let canUse = true;
      let merge = null;
      let taken = false;
      res?.data?.forEach(element => {
        if(element.id !== user?.id)canUse = false
        if(element.barn_id !== userData.barn_id && !taken)merge = element.id
        if(element.barn_id === userData.barn_id) {merge = null;taken=true }
      });
      setMerging(merge)
      setTaken(taken)
      return canUse || merge;
    } catch (err) {
      console.error(err);
      return true;
    }
  }
, 250);
const editUserSchema = Yup.object({
  first_name: Yup.string().test('merging_or_required', 'First Name is Required', function(value) {
    return value || merging
  }),
  last_name: Yup.string().test('merging_or_required', 'Last Name is Required', function(value) {
    return value || merging
  }),
  email: Yup.string().email('Invalid email address').required('Email is required')
    .test(
      'duplicate-email',
      () => {return taken ? 'Another user in this barn is already using that email' : 'Email is in use by a user in another barn. \nThe existing user will be added to this barn'},
      duplicateTest('email')
    ),
  //phone: Yup.string().required('Phone is required'),
  //role: Yup.string().required('Role is required'),
  //assign_horse: Yup.array().min(1, 'At least one horse must be assigned'),
});

  const { mutateAsync: submitRequest } = useMutation({
    mutationKey: 'add-user',
    networkMode: 'always',
    mutationFn: async data => {
      
      data.is_admin = data.admin == true || data.admin == 1 ? true : false;
      data.is_barn_manager = data.manager == true || data.manager == 1 ? true : false;
      data.is_vet = data.vet == true || data.vet == 1? true : false;
      let id = merging || user?.id

      delete data.admin
      delete data.manager
      delete data.vet
      if(id) {
        await Client.put(`/users/${id}`, data)
      } else {
        await Client.post(`/users`, data)
        alert(`Invitation email sent! ${data.first_name} should have an email within a few minutes.`)
      }
    },
    onSuccess: values => {
      onSuccess()
      setShowModal(false)
    },
  });

  const horsesUri = useMemo(() => {
    const url = new URL("/horses?orderBy=name", ENDPOINT);
    return url.toString().replace(ENDPOINT, "");
  }, []);

  // get horses
  const { data: horsesData, refetch: refetchHorses } = useQuery(
    horsesUri
  );

  const initialValues = {
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    phone: user?.phone,
    assign_horse: user?.horses_owned?.map(h => h.id),
    manager: user?.is_barn_manager ? 1 : 0,
    vet: user?.is_vet ? 1 : 0,
    admin: user?.is_admin ? 1 : 0,
  };

  console.log(initialValues)
  console.log("user", user)
  const horses = horsesData?.data.map((h) => ({
    label: h.name,
    value: h.id
  }));

  const header = (
    <span css={css.dialogHeader} className="dialog-header">
      {user? "Edit a User" : "Add a User"}
    </span>
  );

  const footer = (formik) => (
    <ModalButtonSet
      onCancel={handleCancel}
      // isSubmitting={formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      disabled={!formik.dirty}
      submitButtonText="Save changes"
      submitButtonColor={appColors?.greens?.base}
    />
  );
  
  return (
    <>
      {/* <Modal title="Edit a User" open={isModalOpen} footer={null}> */}
      <Formik
          initialValues={initialValues}
          onSubmit={submitRequest}
          validationSchema={editUserSchema}
          //enabledReinitialize
          validateOnBlur
          validateOnChange
        >
          {(formik) => (
            <Dialog
              header={header}
              footer={footer(formik)}
              visible={true}
              style={{ width: 400 }}
              onHide={() => setShowModal(false)}
            >
              <Form style={{
                ...flexbox('column', 'flex-start', 'flex-start', 16),
              }}>

                <div style={{
                  ...flexbox('row', 'flex-start', 'flex-start', 8, 'wrap'),
                  ...fullWidth(),
                }}>
                  <label style={{
                    ...fontbox(appFonts?.inter, 14, 700, 24),
                    ...fullWidth(),
                    color: appColors?.grays?.[700],
                  }}>Special Permissions:</label>
                  {(userData.is_admin ? ["Admin", "Manager", "Vet"] : ["Manager", "Vet"]).map((role) => {
                      return (
                        <FormCheckBox
                          key={role.toLowerCase()}
                          name={role.toLowerCase()}
                          label={role}
                          formik={formik}
                        />
                      );
                    })}
                </div>

                <FormTextInput
                  formik={formik}
                  name="email"
                  label="Email"
                  onBlur={async (e) => {formik.handleBlur(e)}}
                  required={true}
                />

                <FormTextInput
                  formik={formik}
                  name="first_name"
                  label="First Name"
                  labelPosition="top"
                  readOnly={merging}
                  required={true}
                />

                <FormTextInput
                  formik={formik}
                  name="last_name"
                  label="Last Name"
                  labelPosition="top"
                  readOnly={merging}
                  required={true}
                />

                <FormTextInput
                  formik={formik}
                  name="phone"
                  label="Phone"
                  labelPosition="top"
                  readOnly={merging}
                />

                <FormSelect
                  formik={formik}
                  name="assign_horse"
                  label="Assign Horse(s) this user is a guardian for"
                  placeholder="Select All That Apply"
                  options={horses}
                  isMulti={true}
                  onBlur={formik.handleBlur}
                />
              </Form>
            </Dialog>
          )}
      </Formik>
    </>
  );
}