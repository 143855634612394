/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react'
import { css } from './css'
import { appColors } from '../../emotion/appColors'
import React, { useState, useEffect } from 'react'
import ModalButtonSet from '../modal-set-button/modal-set-button'
import FormTextInput from '../../components/form-text-input/form-text-input'
import FormTextarea from '../../components/form-textarea/form-textarea'
import FormSelect from '../../components/form-select/form-select-simplified'
import { useMutation } from '../../hooks'
import Client from '../../client'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import addHorseSchema from '../../schemas/addHorseSchema'

export default function AddHorseModal({
  setShowModal = () => {},
  open = true,
  onSuccess = () => {},
}) {
  const initialValues = {
    name: '',
    description: '',
    ferrier_name: '',
    ferrier_phone: '',
  }
  const handleClose = () => {
    setShowModal(false)
  }

  const [hasError, setHasError] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(open)

  const handleCancel = () => {
    setIsModalOpen(false)
    setShowModal(false)
  }

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: 'add-horse',
    networkMode: 'always',
    mutationFn: async data => Client.post('/horses', data),
    onSuccess: values => {
      setShowModal(false)
      onSuccess(values.body)
    },
    onError: () => {
      setHasError(true)
    },
  })

  return (
    <Dialog
      header={
        <span css={css.dialogHeader} className="dialog-header">
          Stall Settings
        </span>
      }
      visible={isModalOpen}
      style={{ width: '400px' }}
      onHide={handleCancel}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await submitRequest(values)
          } catch (error) {
            console.error(error)
          } finally {
            setSubmitting(false)
          }
        }}
        validationSchema={Yup.object({
          right_rear_camera: Yup.string().label('Right Rear Camera').required(),
          //left_rear_camera: Yup.string().label('Left Rear Camera').required(),
          //ferrier_name: Yup.string().label("Ferrier Name").required(),
          //ferrier_phone: Yup.string().label("Ferrier Phone").required(),
        })}
      >
        {formik => (
          <Form>
            <FormTextInput
              formik={formik}
              name="right_rear_camera"
              label="Right Rear Camera"
              labelPosition="top"
              placeholder="2"
            />
            {formik.errors.right_rear_camera && (
              <div css={css.feedback}>{formik.errors.right_rear_camera}</div>
            )}

            <FormTextInput
              formik={formik}
              name="left_rear_camera"
              label="Left Rear Camera"
              labelPosition="top"
              placeholder="3"
            />
            {formik.errors.left_rear_camera && (
              <div css={css.feedback}>{formik.errors.left_rear_camera}</div>
            )}
            <FormTextInput
              formik={formik}
              name="center_front_camera"
              label="Center Front Camera"
              labelPosition="top"
              placeholder="4"
            />
            {formik.errors.center_front_camera && (
              <div css={css.feedback}>{formik.errors.center_front_camera}</div>
            )}
            <FormTextInput
              formik={formik}
              name="stall_number"
              label="Stall Number/Name"
              labelPosition="top"
              placeholder="4"
            />
            {formik.errors.stall_number && (
              <div css={css.feedback}>{formik.errors.stall_number}</div>
            )}
            
            <ModalButtonSet
              onCancel={handleCancel}
              isSubmitting={formik.isSubmitting}
              submitButtonText="Save"
              submitButtonColor={appColors.greens.base}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
