/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { Toast } from "primereact/toast";
import { useQuery } from "../../hooks";
import StallSection from "./stall-section";
import { useAppDispatch, useAppSelector } from '../../shared/model';

import FilterBar from './filter-bar';

function StallHorses() {
  const dispatch = useAppDispatch();

  const { id: stallId } = useParams();

  const toastRef = useRef();

  const [search, setSearch] = useState('');

  const [activeBtnGroup, setActiveBtnGroup] = useState("cards");

  //const { data, refetch } = useQuery(`/stall/${stallId}`);
let data = {}
  return (
    <>
      <Toast ref={toastRef} position="top-center" />
      <StallSection data={data} horses={data?.horse} activeBtnGroup={activeBtnGroup} />
    </>
  );
}

export default StallHorses;