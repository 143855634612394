/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTheme } from "@emotion/react";
import { css } from "./css";
import { Button } from 'primereact/button';
import DeleteClipModal from "../modals/DeleteClipModal";
import Client from '../../client';
import { changeCurrentHorse, selectedCurrentHorse } from "../../store/horseSlice";
import { useAppDispatch, useAppSelector } from "../../shared/model";

export default function DeleteClipButton(props) {
  const [isDeleteClipModalOpen, setDeleteClipModalOpen] = useState(false);

  const currentHorse = useAppSelector(selectedCurrentHorse);

  const deleteClip = async () => {
    setDeleteClipModalOpen(true);
  };

  const closeModal = () => {
    setDeleteClipModalOpen(false);
  };

  return (
    <>
      <Button css={css.deleteClipButton} onClick={deleteClip}>
        Delete Clip
      </Button>
      {isDeleteClipModalOpen && (
        <DeleteClipModal isOpen={isDeleteClipModalOpen} onClose={closeModal} />
      )}
    </>
  );
}