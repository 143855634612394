import {
  borderRadius,
  darken,
  fontbox,
  margin,
  paddingX,
  paddingY,
  transition,
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = {
  deleteClipButton: theme => ({
    ...borderRadius(8),
    ...fontbox(appFonts.inter, 14, 700, 14),
    ...paddingX(10),
    ...paddingY(14),
    ...transition(),
    ...margin(0, 0, 8, 0),
    justifyContent: 'center',
    width: '100%',
    backgroundColor: appColors.grays[0],
    border: `1px solid ${appColors.reds.base}`,
    color: appColors.reds.base,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: darken(appColors.reds.base, -0.05),
      color: appColors.grays[0],
    },

    '&.active': {
      backgroundColor: appColors.blues.pillactivebg,
      border: `1px solid ${appColors.blues.pillborderactive}`,
      color: appColors.blues.pillactive,
    },

    link: {
      ...borderRadius(0),
      ...paddingX(0),
      ...paddingY(0),
    },
  }),
}
