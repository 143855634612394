/** @jsxImportSource @emotion/react */

import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropdown } from 'primereact/dropdown'
import { confirmDialog } from 'primereact/confirmdialog'

import { TOP_LEVEL_PAGES } from '../constants'
import AuthContext from '../../context/auth-context'
import { useQuery } from '../../hooks'

import ConfirmDialog from '../confirm-dialog/confirm-dialog'
import Button from '../button/button'
import Icon from '../icon/icon'

import MobTrigger from './menu-trigger'

import { css } from './css'

function Menu({ activePage, setActivePage, navOpen, setNavOpen, hideMenu }) {
  const navigate = useNavigate()

  const { logout, userData, selectCompany } = useContext(AuthContext)
  const { data, refetch } = useQuery('/barns')
  const [barnId, setBarnId] = useState(null)
  const [barnOptions, setBarnOptions] = useState([])

  const trigger = <MobTrigger navOpen={navOpen} setNavOpen={setNavOpen} />

  const showBarnOptions = userData?.is_admin || userData?.user_to_barn?.length > 0 ? true : false
  useEffect(() => {
    const opts = data?.data
      ?.filter(
        b =>
          userData?.is_admin ||
          userData?.user_to_barn?.find(b2 => b.id === b2.barn_id),
      )
      .sort((a,b) => a.name > b.name ? 1 : -1)
      .map(b => {
        return {
          value: b.id,
          label: b.name,
        }
      })
    if (userData?.is_admin && !userData?.barn_id) {
      if (opts?.length > 0) {
        const user = { ...userData }
        user.barn_id = opts[0].value
        user.barn_name = opts[0].label
        selectCompany(user)
        setBarnId(user.barn_id)
      }
    } else if(!userData?.barn_id && opts.length > 0) {
      const user = { ...userData }
      user.barn_id = opts[0].value
      selectCompany(user)
      setBarnId(user.barn_id)
    }
    if(userData.barn_id) {
      setBarnId(userData.barn_id)
    }
    setBarnOptions(opts)
  }, [data, userData])

  const getIcon = icon => {
    if (!icon) return null

    return <Icon icon={icon} />
  }

  const pageEls = !hideMenu
    ? TOP_LEVEL_PAGES.filter(
        p => userData.is_admin || p.visibility.find(r => userData[r]),
      ).map(page => {
        return (
          <li
            key={page?.path}
            onClick={() => {
              setNavOpen(false)
              navigate(`/${page?.path}`)
              setActivePage(page?.path)
            }}
            className={activePage === page?.path ? 'active' : ''}
          >
            {getIcon(page?.icon)}
            {page?.display}
          </li>
        )
      })
    : null

  const checkLogout = () => {
    confirmDialog({
      group: 'headless',
      message: `Are you sure you want to logout, ${userData?.first_name} ${userData?.last_name}?`,
      position: 'top-right',
    })
  }

  const dialogContent = ({ headerRef, contentRef, footerRef, hide, message }) => (
    <div css={css?.confirmDialog}>
      {message?.header && (
        <span css={css?.confirmDialog?.content} ref={headerRef}>
          {message.header}
        </span>
      )}

      <div css={css?.confirmDialog?.content}>
        <p className="mb-0" ref={contentRef}>
          {message.message}
        </p>
      </div>

      <div css={css?.confirmDialog?.footer} ref={footerRef}>
        <Button
          label='Go Back'
          variant='outlined'
          onClick={event => {
            hide(event)
          }}
        >{message?.rejectLabel}</Button>

        <Button
          label='Logout'
          color="danger"
          onClick={event => {
            hide(event)
            logout()
          }}
        />
      </div>
    </div>
  )

  const navigation = (
    <nav css={css?.navigation} className={`main-menu ${navOpen ? 'open' : ''}`}>
      <ul>
        {pageEls}

        {showBarnOptions && (
          <Dropdown
            options={barnOptions}
            label={'Default Camera'}
            css={css?.navigation?.dropdown}
            value={barnId}
            onChange={it => {
              const user = { ...userData }
              user.barn_id = it.target.value
              selectCompany(user)
              setBarnId(user.barn_id)
            }}
          />
        )}

        {/* {userData?.barn_name && (
          <li css={css?.navigation?.barnName}><label>{userData?.barn_name}</label></li>
        )} */}

        <li className="logout" onClick={checkLogout}>
          <Icon icon="Signout" />
          <span>Logout</span>
        </li>
      </ul>
    </nav>
  )

  const confirmBlock = (
    <ConfirmDialog
      group="headless"
      content={dialogContent}
    />
  )

  return (
    <>
      {trigger}
      {navigation}
      {confirmBlock}
    </>
  )
}

export default Menu
