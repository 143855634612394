/** @jsxImportSource @emotion/react */
import { Dialog } from 'primereact/dialog'

import ModalButtonSet from '../modal-set-button/modal-set-button'

import { appColors } from '../../emotion/appColors'
import { css } from './css'

export default function DeleteClipModal({ isOpen, onClose }) {
  const handleCancel = () => {
    onClose()
  }

  const handleSubmit = () => {
    onClose()
  }

  return (
    <Dialog
      header={
        <span css={css.dialogHeader} className="dialog-header">
          Delete Clip
        </span>
      }
      visible={isOpen}
      style={{ width: '400px' }}
      onHide={onClose}
    >
      <div>
        <p css={css.subheader}>Are you sure you want to delete this clip?</p>
        <ModalButtonSet
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          submitButtonText="Delete"
          submitButtonColor={appColors.reds.base}
        />
      </div>
    </Dialog>
  )
}
